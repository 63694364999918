import Vue from 'vue'
import VueRouter from 'vue-router'
import { Role } from '@/util/role.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'notFound',
    component: () =>
      import(/* webpackChunkName: "not-found" */ '@/components/NotFound.vue')
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/components/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import(/* webpackChunkName: "register" */ '@/components/Register.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      authorize: [Role.Admin]
    }
  },
  {
    path: '/cars',
    name: 'cars',
    component: () => import(/* webpackChunkName: "cars" */ '@/views/Cars.vue'),
    meta: {
      requiresAuth: true,
      authorize: [Role.User]
    }
  },
  {
    path: '/cars/:carId',
    name: 'car',
    component: () =>
      import(/* webpackChunkName: "car" */ '@/views/CarDetail.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      authorize: [Role.User]
    }
  },
  {
    path: '/expense-form',
    name: 'carExpenseForm',
    component: () =>
      import(
        /* webpackChunkName: "carExpenseForm" */ '@/views/CarExpenseForm.vue'
      ),
    props: true,
    meta: {
      requiresAuth: true,
      authorize: [Role.User]
    }
  },
  {
    path: '/expenses/:carExpenseId',
    name: 'carExpense',
    component: () =>
      import(/* webpackChunkName: "carExpense" */ '@/views/CarExpense.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      authorize: [Role.User]
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/car-comparison',
    name: 'carComparison',
    component: () =>
      import(
        /* webpackChunkName: "carComparison" */ '@/views/CarComparison.vue'
      ),
    meta: {
      requiresAuth: true,
      authorize: [Role.User]
    }
  },
  {
    path: '/car-services',
    name: 'carServices',
    component: () =>
      import(/* webpackChunkName: "carServices" */ '@/views/CarServices.vue'),
    meta: {
      requiresAuth: true,
      authorize: [Role.User]
    }
  },
  {
    path: '/car-services-list',
    name: 'carServicesList',
    component: () =>
      import(
        /* webpackChunkName: "carServicesList" */ '@/views/CarServicesList.vue'
      ),
    meta: {
      requiresAuth: true,
      authorize: [Role.Mechanic]
    }
  }
]

// httpClient.interceptors.response.use(
//   response => response,
//   error => {
//     if (
//       error.response.status == 401 &&
//       error.response.data.message == 'Expired JWT Token'
//     ) {
//       this.$store
//         .dispatch('refreshToken')
//         .then(() => {
//           this.$buefy.snackbar.open({
//             message: `Successfully refresh token`,
//             type: 'is-success',
//             position: 'is-top'
//           })
//         })
//         .catch(() => this.dispatch('logout'))
//     }
//     return Promise.reject(error)
//   }
// )

const router = new VueRouter({
  routes,
  mode: 'hash'
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('auth')

  // prevent anonymous users to access protected routes
  if (to.matched.some(route => route.meta.requiresAuth) && !isLoggedIn) {
    next({ name: 'login' })
  }

  if ((to.name == 'login' || to.name == 'register') && isLoggedIn) {
    next({ name: 'dashboard' })
  }

  next()
})

export default router
