import Vue from 'vue'
import Vuex from 'vuex'
import { httpClient } from '@/api/httpClient.js'
import { httpImageClient } from '@/api/httpClient.js'
import { Role } from '@/util/role.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: null,
    user: {}
  },
  mutations: {
    SET_AUTH(state, { token, refreshToken, data }) {
      const decodedPayload = JSON.parse(atob(token.split('.')[1]))
      const auth = {
        id: decodedPayload.id,
        token,
        refreshToken,
        data
      }
      state.auth = auth
      localStorage.setItem('auth', JSON.stringify(auth))
      httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
      httpImageClient.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${token}`
    },
    SET_USER(state, user) {
      state.user = user
    },
    CLEAR_AUTH() {
      localStorage.removeItem('auth')
      // TODO: remove site reload
      location.reload()
    }
  },
  actions: {
    loginUser({ commit }, credentials) {
      return httpClient.post('/login', credentials).then(({ data }) => {
        commit('SET_AUTH', data)
        this.dispatch('getUserData')
      })
    },
    logoutUser({ commit }) {
      commit('CLEAR_AUTH')
    },
    refreshToken({ commit }) {
      return httpClient
        .post('/token/refresh', { refreshToken: this.getters.getRefreshToken })
        .then(({ data }) => {
          commit('SET_AUTH', data)
          this.dispatch('getUserData')
        })
        .catch(() => this.dispatch('logoutUser'))
    },
    getUserData({ commit }) {
      return httpClient.get(`/environment`).then(({ data }) => {
        commit('SET_USER', data)
      })
    }
  },
  getters: {
    isLoggedIn(state) {
      return !!state.auth
    },
    getUserId(state) {
      return state.auth.id
    },
    getRefreshToken(state) {
      return state.auth.refreshToken
    },
    isAdmin(state) {
      return state.auth?.data?.roles.includes(Role.Admin) || false
    },
    isUser(state) {
      return state.auth?.data?.roles.includes(Role.User) || false
    },
    isMechanic(state) {
      return state.auth?.data?.roles.includes(Role.Mechanic) || false
    }
  }
})
