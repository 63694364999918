import Vue from 'vue'
import App from './App.vue'
// import axios from 'axios'
import Buefy from 'buefy'
import 'bulma-helpers/css/bulma-helpers.min.css'
import router from './router'
import store from './store'
import { httpClient } from '@/api/httpClient.js'

Vue.use(Buefy)
Vue.use(require('vue-moment'))

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    const auth = localStorage.getItem('auth')
    let isRefreshingTokenPromise = null

    if (auth) {
      this.$store.commit('SET_AUTH', JSON.parse(auth))
    }

    httpClient.interceptors.response.use(
      response => response,
      error => {
        if (
          error.response.status == 401 &&
          error.response.data.message == 'Expired JWT Token'
        ) {
          if (!isRefreshingTokenPromise) {
            isRefreshingTokenPromise = this.$store
              .dispatch('refreshToken')
              .then(() => {
                isRefreshingTokenPromise = null
              })
          }
          return isRefreshingTokenPromise.then(() => {
            error.config.headers[
              'Authorization'
            ] = `Bearer ${store.state.auth.token}`
            return httpClient.request(error.config)
          })
        }
        return Promise.reject(error)
      }
    )

    // fake token security check
    // axios.interceptors.response.use(
    //   response => response,
    //   error => {
    //     if (error.response.status == 401) {
    //       this.$store.dispatch('logout')
    //     }
    //     return Promise.reject(error)
    //   }
    // )
  }
}).$mount('#app')
